









































import {Component, Vue} from 'vue-property-decorator';
import {EnvProvider} from '@/utilities';
import ReferIcon from '@/assets/icons/policyAccess/refer-a-friend.svg';
import LottiePlayer from 'vue-lottie-player';
import { getAppUrl, getSiteUrl } from '@/utilities/getUrl';
import { getStatic } from '@/utilities/static';

@Component({
  components: {
    LottiePlayer,
    ReferIcon
  }
})
export default class SuccessHeadSection extends Vue {
  public website: string = getSiteUrl(EnvProvider('URL_WEBSITE_LANDLORDS'));
  public landlordApp: string = getAppUrl('URL_LANDLORD');
  public getStatic = getStatic;
}
