



























import { Component, Vue } from 'vue-property-decorator';
import PolicyDepositAccessHeadSection from '@/pages/PolicyDepositAccess/PolicyDepositAccess.head.section.vue';
import PolicyDepositAccessDetailsSection from '@/pages/PolicyDepositAccess/PolicyDepositAccess.details.section.vue';
import PolicyDepositAccessPartnersSection from '@/pages/PolicyDepositAccess/PolicyDepositAccess.partners.section.vue';
import PolicyDepositAccessAdditionDetailsSection from '@/pages/PolicyDepositAccess/PolicyDepositAccess.addition.details.section.vue';
import PolicyDepositAccessAdditionInfoSection from '@/pages/PolicyDepositAccess/PolicyDepositAccess.addition.info.section.vue';
import PolicyDepositAccessFAQSection from '@/pages/PolicyDepositAccess/PolicyDepositAccess.faq.section.vue';
import Footer from '@/components/Layout/Footer/Footer.vue';
import PolicyDepositAccessSuccess from '@/pages/PolicyDepositAccess/PolicyDepositAccess.success.vue';
import PolicyDepositAccessExistingLandlordSection from '@/pages/PolicyDepositAccess/ExistingLandlord/PolicyDepositAccess.existingLandlord.section.vue';
import PartnerPropertyDetails from '@/pages/PolicyDepositAccess/Partner/Partner.propertyDetails.vue';
import {i18n} from '@/main';
import {EnvProvider} from '@/utilities';
import { landlordTypeEnum } from '@/interfaces';
import { getSiteUrl } from '@/utilities/getUrl';

@Component({
  metaInfo() {
    return {
      title: i18n.tc('meta.invitation.title'),
    };
  },
  components: {
    PolicyDepositAccessHeadSection,
    PolicyDepositAccessDetailsSection,
    PolicyDepositAccessPartnersSection,
    Footer,
    PolicyDepositAccessSuccess,
    PolicyDepositAccessAdditionDetailsSection,
    PolicyDepositAccessAdditionInfoSection,
    PolicyDepositAccessFAQSection,
    PolicyDepositAccessExistingLandlordSection,
    PartnerPropertyDetails,
  }
})
export default class PolicyDepositAccessPage extends Vue {
  public landlordType = landlordTypeEnum;
  private isAcceptSucceeded: boolean = false;
  private isPropertyKnown: boolean = false;

  public created() {
    if (this.$route.query.token) {
      this.$store.dispatch('getAcceptPolicy', this.$route.query.token)
        .then(() => {
          this.isPropertyKnown = this.$store.getters.getAcceptPolicy.propertyKnown
            ? true
            : this.$store.getters.getAcceptPolicy.address
              ? this.$store.getters.getAcceptPolicy.address.type === 'MULTIFAMILY'
              : false;
        })
        .catch(() => {
          window.location.href = getSiteUrl(EnvProvider('URL_WEBSITE_EXPIRED'));
        });
    } else {
      this.$router.push({name: 'index'});
    }
  }

  public mounted() {
    this.$root.$on(
      'acceptSucceeded',
      (value) => this.isAcceptSucceeded = value
    );
  }
}
