








import {Component, Prop, Vue} from 'vue-property-decorator';
import quoteIcon from '@/assets/icons/policyAccess/quote-icon.svg';

@Component({
  components: {quoteIcon}
})
export default class Quote extends Vue {
  @Prop({
    type: String,
    required: true
  })
  private title!: string;

  @Prop({
    type: String,
    required: true,
  })
  private text!: string;
}
