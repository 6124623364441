



































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {createHelpers} from 'vuex-map-fields';
import {IPolicyLandlordDetails} from '@/services/api/user.service';
import Address from '@/components/Address/Address.vue';
import PolicyAccessManualBeneficiary from '@/pages/PolicyDepositAccess/ExistingLandlord/Form/PolicyAccess.manualBeneficiary.vue';
import { scrollTop } from '@/utilities';
import {
  LandlordEnum, documentNamesEnum,
  PropertiesTypeEnum
} from '@/interfaces';
import {capitalize} from '@/utilities';
import '@/validators/vee-validate';
import {mixins} from 'vue-class-component';
import ServerValidation from '@/mixins/serverValidation.mixin';
import InformationIcon from '@/assets/icons/policyAccess/information.svg';
import {getChildRefs} from '@/utilities/getChildRefs';
import DocumentLink from '@/components/Ui/DocumentLink.vue';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    DocumentLink,
    ValidationObserver,
    ValidationProvider,
    Address,
    PolicyAccessManualBeneficiary,
    InformationIcon
  },
  computed: { ...mapFields(['policyLandlordDetails']) }
})
export default class PolicyDepositAccessAdditionDetailsSection extends mixins(ServerValidation) {
  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>,
    address: Vue,
  };
  public documentNamesEnum = documentNamesEnum;
  private LandlordEnum = LandlordEnum;
  private policyLandlordDetails!: IPolicyLandlordDetails;
  private policyAccepted: boolean = false;
  private loading: boolean = false;
  private isLandlordAddressValid: boolean = false;
  private isLandlordManualAddress: boolean = false;
  private isBeneficiaryAddressValid: boolean = false;
  private isBeneficiaryManualAddress: boolean = false;
  private errorCheckbox: string = '';

  public created(): void {
    this.$store.dispatch('getDictionary', 'residential-property-types');
    this.$store.dispatch('getDictionary', 'countries');
  }

  private get getPropertyType() {
    return this.$store.getters.getDictionaryArray( 'residential-property-types');
  }

  private get isUnitNameRequired() {
    return this.policyLandlordDetails.propertyType === PropertiesTypeEnum.MULTIFAMILY;
  }

  private onChangeCheckbox() {
    this.errorCheckbox = '';
  }

  private async onSubmit() {
    if (!this.policyAccepted) {
      // @ts-ignore
      this.errorCheckbox = this.$t('validation.checkbox');
    }
    const validation = await this.$refs.observer?.validateWithInfo();
    if (!this.isFieldsValid || !validation.isValid) {
      this.validateForm(getChildRefs(this), validation.fields);
      return;
    }
    this.loading = true;
    this.$store.dispatch(
      'confirmLandlordDetails',
      {token: this.$route.query.token,
        isLandlordManualAddress: this.isLandlordManualAddress,
        isBeneficiaryManualAddress: this.isBeneficiaryManualAddress}
    )
      .then(() => {
        scrollTop();
        this.$root.$emit('acceptSucceeded', true);
      })
      .catch((error) => {
      this.setServerValidationErrors(
        this.$refs.observer,
        this.getServerErrors(error)
      );
      // @ts-ignore
      this.addressErrors = this.getServerErrors(error).address;
    })
      .finally(() => this.loading = false);
  }

  private get isFieldsValid() {
    return !!(
      (this.policyLandlordDetails.type === this.LandlordEnum.PRIVATE ? true : this.policyLandlordDetails.companyName)
      && this.policyAccepted
      && this.policyLandlordDetails.type
      && this.isLandlordAddressValid
      && (this.policyLandlordDetails.isLandlordBeneficiary ? true : this.isBeneficiaryAddressValid));
   }

   private get listItemAnswer() {
    return [
      {
        value: true,
        title: this.$tc('policyAccess.summary.propertyDetails.answerYes')
      },
      {
        value: false,
        title: this.$tc('policyAccess.summary.propertyDetails.answerNo')
      },
    ];
   }

  private get listItem() {
    return [
      {
        value: this.LandlordEnum.PRIVATE,
        title: this.$tc('policyAccess.summary.landlordType.type.private')
      },
      {
        value: this.LandlordEnum.PROPERTY_MANAGER,
        title: this.$tc('policyAccess.summary.landlordType.type.propertyManager')
      },
      {
        value: this.LandlordEnum.INSTITUTIONAL,
        title: this.$tc('policyAccess.summary.landlordType.type.agent')
      },
      {
        value: this.LandlordEnum.MUNICIPALITY,
        title: this.$tc('policyAccess.summary.landlordType.type.cooperative')
      },
    ];
  }

  private get isCompanyNameRequired() {
    return !(
      [
        LandlordEnum.PRIVATE,
        LandlordEnum.INSTITUTIONAL
      ].includes(this.$store.getters.user.type)
    );
  }

  private set landlordFirstName(val) {
    this.policyLandlordDetails.firstName = capitalize(val);
  }

  private get landlordFirstName() {
    return this.policyLandlordDetails.firstName;
  }

  private set landlordLastName(val) {
    this.policyLandlordDetails.lastName = capitalize(val);
  }

  private get landlordLastName() {
    return this.policyLandlordDetails.lastName;
  }

}
