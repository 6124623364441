
























import {Component, Vue} from 'vue-property-decorator';
import Section from '@/components/Ui/Section.vue';
import BigImageDe from '@/assets/images/PolicyDepositeAccess/laptop-illustration-de.svg';
import BigImageEn from '@/assets/images/PolicyDepositeAccess/laptop-illustration-en.svg';
import SmallImage from '@/assets/images/PolicyDepositeAccess/laptop_illustration.svg';

@Component({
  components: {
    Section,
    BigImageDe,
    BigImageEn,
    SmallImage
  }
})
export default class SuccessManageDepositSection extends Vue {}
