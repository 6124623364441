














































import {Component, Prop, Watch} from 'vue-property-decorator';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import ServerValidation from '@/mixins/serverValidation.mixin';
import {mixins} from 'vue-class-component';
import {IPolicyLandlordDetails} from '@/services/api/user.service';
import Address from '@/components/Address/Address.vue';
import {createHelpers} from 'vuex-map-fields';
import FormObserverMixin from '@/mixins/formObserver.mixin';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    Address},
  computed: { ...mapFields(['policyLandlordDetails']) }
})

export default class PolicyAccessManualBeneficiary extends mixins(ServerValidation, FormObserverMixin) {

  private policyLandlordDetails!: IPolicyLandlordDetails;
  private isManualAddress: boolean = false;
  private isAddressValid: boolean = false;

  @Prop({type: Boolean, default: false})
  private hideBackToSelect: boolean | undefined;

  @Watch('isAddressValid')
  private watchAddressValid(value) {
    this.$emit('setAddressValid', value);
  }

  @Watch('isManualAddress')
  private watchManualAddress(value) {
    this.$emit('setManualAddress', value);
  }

  private setExistingBeneficiaryManual() {
    this.$emit('setExistingBeneficiaryManual', true);
  }
}
