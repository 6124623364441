




































import {Component, Vue} from 'vue-property-decorator';
import {ValidationProvider} from 'vee-validate';
import {IPolicyLandlordDetails} from '@/services/api/user.service';
import {createHelpers} from 'vuex-map-fields';
import {PropertiesTypeEnum} from '@/interfaces';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: { ValidationProvider},
  computed: { ...mapFields(['policyLandlordDetails']) }
})

export default class PolicyDepositAccessPropertyDetailsFields extends Vue {

  private policyLandlordDetails!: IPolicyLandlordDetails;

  private get getPropertyType() {
    return this.$store.getters.getDictionaryArray( 'residential-property-types');
  }

  private get isUnitNameRequired() {
    return this.policyLandlordDetails.propertyType === PropertiesTypeEnum.MULTIFAMILY;
  }

}
