







import {Component, Vue} from 'vue-property-decorator';
import SuccessHeadSection from '@/pages/PolicyDepositAccess/Success/Success.head.section.vue';
import SuccessManageDepositSection from '@/pages/PolicyDepositAccess/Success/Success.manageDeposit.section.vue';
import SuccessHelpSection from '@/pages/PolicyDepositAccess/Success/Success.help.section.vue';

@Component({
    components: {
      SuccessHeadSection,
      SuccessManageDepositSection,
      SuccessHelpSection,
    }
  }
)
export default class PolicyDepositAccessSuccess extends Vue {}
