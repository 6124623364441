













































import {Component, Watch} from 'vue-property-decorator';
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {scrollTop} from '@/utilities';
import ServerValidation from '@/mixins/serverValidation.mixin';
import {mixins} from 'vue-class-component';
import {IPolicyAccess, IPolicyLandlordDetails} from '@/services/api/user.service';
import PolicyDepositAccessSummaryInfo from '@/pages/PolicyDepositAccess/PolicyDepositAccess.summary.info.vue';
import PolicyDepositAccessPropertyDetailsFields from '@/pages/PolicyDepositAccess/ExistingLandlord/Form/PolicyDepositAccess.propertyDetails.fields.vue';
import {createHelpers} from 'vuex-map-fields';
import PolicyAccessManualBeneficiary from '@/pages/PolicyDepositAccess/ExistingLandlord/Form/PolicyAccess.manualBeneficiary.vue';
import PolicyAccessSelectBeneficiary from '@/pages/PolicyDepositAccess/ExistingLandlord/Form/PolicyAccess.selectBeneficiary.vue';
import {defaultBeneficiary} from '@/store/modules/user.store';
import InformationIcon from '@/assets/icons/policyAccess/information.svg';
import {getChildRefs} from '@/utilities/getChildRefs';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    PolicyAccessManualBeneficiary,
    PolicyDepositAccessSummaryInfo,
    ExistingLandlordForm,
    ValidationObserver,
    ValidationProvider,
    PolicyDepositAccessPropertyDetailsFields,
    PolicyAccessSelectBeneficiary,
    InformationIcon
  },
  computed: { ...mapFields(['policyLandlordDetails']) }
})

export default class ExistingLandlordForm extends mixins(ServerValidation) {
  public $refs!: {
    observer: InstanceType<typeof ValidationObserver>,
  };

  private policyLandlordDetails!: IPolicyLandlordDetails;
  private loading: boolean = false;
  private isAddressValid: boolean = false;
  private isManualAddress: boolean = false;
  private isBeneficiaryExists: boolean = true;

  public created() {
    this.$store.dispatch('getDictionary', 'countries');
    this.$store.dispatch('getDictionary', 'residential-property-types');
  }

  private async onSubmit() {
    const validation = await this.$refs.observer?.validateWithInfo();
    if (!this.isFieldsValid || !validation.isValid) {
      this.validateForm(getChildRefs(this), validation.fields);
      return;
    }
    this.loading = true;
    this.$store.dispatch('confirmLandlordAcceptance',
      {token: this.$route.query.token, isManualAddress: this.isManualAddress})
      .then(() => {
        scrollTop();
        this.$root.$emit('acceptSucceeded', true);
      })
      .catch((error) => {
        this.$notify({
          group: 'notification',
          type: 'notification-error',
          text: error.response.data.message,
        });
        this.setServerValidationErrors(
          this.$refs.observer,
          this.getServerErrors(error)
        );
        // @ts-ignore
        this.addressErrors = this.getServerErrors(error).address;
      })
      .finally(() => this.loading = false);
  }

  @Watch('isBeneficiaryExists')
  private watchBeneficiaryExists(val) {
    if (val) {
      this.policyLandlordDetails.beneficiary = {
        ...defaultBeneficiary(),
        id: this.policyLandlordDetails.beneficiary.id
      };
    } else {
      this.policyLandlordDetails.beneficiary.id = null;
    }
  }

  private get isFieldsValid() {
    return (this.isBeneficiaryExists ?
      !!this.policyLandlordDetails.beneficiary.id : this.isAddressValid);
  }

  private get getPolicyInfo(): IPolicyAccess {
    return this.$store.getters.getAcceptPolicy;
  }

}
