















import { Component, Vue } from 'vue-property-decorator';
import {EnvProvider} from '@/utilities';
import { getSiteUrl } from '@/utilities/getUrl';
@Component
export default class PolicyDepositAccessAdditionInfoSection extends Vue {
  private get links() {
    return [
      {
        text: this.$tc('policyAccess.faqLinks.links.0.text'),
        link: this.getContactUsLink
      },
      {
        text: this.$tc('policyAccess.faqLinks.links.1.text'),
        link: this.getFaqLink
      }
    ];
  }

  private get getContactUsLink(): string {
    return getSiteUrl(EnvProvider('URL_WEBSITE_CONTACT_US'));
  }

  private get getFaqLink(): string {
    return getSiteUrl(EnvProvider('URL_WEBSITE_FAQ'));
  }
}
