















import {Component, Vue} from 'vue-property-decorator';
import {IDescription} from '@/interfaces';
import unpaidIcon from '@/assets/icons/policyAccess/unpaid-utilities-icon.svg';
import excessiveIcon from '@/assets/icons/policyAccess/excessive-damage-icon.svg';
import rentIcon from '@/assets/icons/policyAccess/unpaid-rent-icon.svg';

@Component
export default class PolicyDepositAccessSummaryInfo extends Vue {
  private get detailsInfo(): IDescription[] {
    return [
      {
        title: this.$tc('policyAccess.summary.info.items.0'),
        component: unpaidIcon
      },
      {
        title: this.$tc('policyAccess.summary.info.items.1'),
        component: excessiveIcon
      },
      {
        title: this.$tc('policyAccess.summary.info.items.2'),
        component: rentIcon
      }
    ];
  }
}
