import { render, staticRenderFns } from "./PolicyDepositAccess.addition.details.section.vue?vue&type=template&id=4f6431ba&scoped=true&lang=pug&"
import script from "./PolicyDepositAccess.addition.details.section.vue?vue&type=script&lang=ts&"
export * from "./PolicyDepositAccess.addition.details.section.vue?vue&type=script&lang=ts&"
import style0 from "./PolicyDepositAccess.addition.details.section.vue?vue&type=style&index=0&id=4f6431ba&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f6431ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCheckbox,VForm,VIcon,VRadio,VRadioGroup,VTooltip})
