









































































import { Component, Prop, Vue } from 'vue-property-decorator';
import {IPolicyAccess} from '@/services/api/user.service';
import moment from 'moment-timezone';
import PolicyDepositAccessSummaryInfo from '@/pages/PolicyDepositAccess/PolicyDepositAccess.summary.info.vue';
import PartnerAccept from '@/pages/PolicyDepositAccess/Partner/Partner.accept.vue';
import { documentNamesEnum } from '@/interfaces';
import DocumentLink from '@/components/Ui/DocumentLink.vue';

@Component({
  components: {
    DocumentLink,
    PolicyDepositAccessSummaryInfo,
    PartnerAccept,
  }
})
export default class PolicyDepositAccessDetailsSection extends Vue {
  public documentNamesEnum = documentNamesEnum;

  @Prop({ type: Boolean, required: true, default: false})
  private isPropertyKnown!: boolean;

  private get getPolicyInfo(): IPolicyAccess {
    return this.$store.getters.getAcceptPolicy;
  }

  private get tenantAddressTitle() {
    return this.isPropertyKnown
      ? this.getPolicyInfo.address?.title ?? ''
      : this.getPolicyInfo.tenantAddress;
  }

  private getFormatDate(date: string) {
    return date
      ? moment(date).format('DD.MM.YYYY')
      : '';
  }

  private formatCurrency(amount, currency = 'EUR') {
    return this.$options.filters?.[currency](amount) ?? amount;
  }
}
