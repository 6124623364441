










































































import { Component } from 'vue-property-decorator';
import {ISuggestionAddress, ISuggestionsPlaces, PropertiesTypeEnum, uniformLoadingEnum} from '@/interfaces';
import { defaultSuggestionAddress } from '@/store/modules/user.store';
import { mixins } from 'vue-class-component';
import ServerValidation from '@/mixins/serverValidation.mixin';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import '@/validators/vee-validate';
import PartnerAccept from '@/pages/PolicyDepositAccess/Partner/Partner.accept.vue';
import {createHelpers} from 'vuex-map-fields';
import {IPolicyAccess} from '@/services/api/user.service';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    PartnerAccept,
  },
  computed: { ...mapFields(['policyAccessInfo']) }

})
export default class PartnerPropertyDetails extends mixins(ServerValidation) {
  public PropertiesTypeEnum = PropertiesTypeEnum;
  private unitSuggestionListRecord: ISuggestionAddress[] = [];
  private unitSuggestionList: ISuggestionAddress[] = [];
  private buildingSuggestionList: ISuggestionAddress[] = [];
  private buildingSearch: null | string = null;
  private unitSearch: null | string = null;
  private address: ISuggestionAddress = defaultSuggestionAddress();
  private policyAccessInfo!: IPolicyAccess;

  public mounted() {
    this.buildingSuggestionList = [this.policyAccessInfo.address];
    this.onUnitLoad('');
  }

  private onUnitLoad(event) {
    const isToSearch = this.unitSuggestionList.findIndex((address) => address.title === event);

    if (isToSearch !== -1) { return; }

    this.$store.dispatch('getPartnerUnits', {
      token: this.$route.query.token,
      query: event,
      buildingId: this.policyAccessInfo.address.placeId,
    })
      .then((response: ISuggestionsPlaces) => {
        this.unitSuggestionListRecord = response.suggestions;
        this.unitSuggestionList = this.unitSuggestionListRecord;
      });
  }
  
  private onBuildingSearch(event) {
    const isToSearch = this.buildingSuggestionList.findIndex((address) => address.title === event);

    if (isToSearch !== -1) { return; }

    this.$store.dispatch('getPartnerBuildings', {
      query: event,
      token: this.$route.query.token,
    })
      .then((response: ISuggestionsPlaces) => {
        this.buildingSuggestionList = response.suggestions;
        this.address.placeId = '';
        this.unitSuggestionList = [];
      });
  }

  private inputBuildingAddress(event: ISuggestionAddress) {
    this.policyAccessInfo.address = event ?? defaultSuggestionAddress();
    this.address.placeId = '';
    this.unitSuggestionList = [];
  }

  private onChangeBuildingAddress(event: ISuggestionAddress) {
    this.policyAccessInfo.address = event ?? defaultSuggestionAddress();
    this.onUnitLoad('');
  }

  private resetBuildingInputs() {
    (this.$refs.observer as InstanceType<typeof ValidationObserver>).reset();
    this.policyAccessInfo.address.placeId = '';
    this.buildingSuggestionList = [];
  }

  private get isDisabled() {
    return this.policyAccessInfo.address.type === PropertiesTypeEnum.MULTIFAMILY
      ? !this.address.placeId
      : !this.policyAccessInfo.address.placeId;
  }

  public get buildingsLoading() {
    return this.$store.getters.isUniformLoading(uniformLoadingEnum.buildingsLoad);
  }
}
