export const getChildRefs = (component) => {
  let childRefs = {};
  const keys = Object.keys(component.$refs);
  if (keys.length) {
    keys.forEach((key) => {
      const ref = component.$refs[key];
      if (ref) {
        childRefs[key] = ref;
        if (ref.$refs) {
          childRefs = {...childRefs, ...getChildRefs(ref)};
        }
      }
    });
  }
  return childRefs;
};
