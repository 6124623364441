var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"py-20 px-4 px-md-13 py-lg-22"},[_c('div',{staticClass:"container"},[_c('v-row',{staticClass:"row-content align-center pb-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"header text-center"},[_c('div',{staticClass:"text-h2 mb-2"},[_vm._v(_vm._s(_vm.$t('policyAccess.existingLandlord.addMoreDetails.title')))]),_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.$t('policyAccess.existingLandlord.addMoreDetails.subtitle')))])])])],1),_c('v-row',[_c('v-col',{staticClass:"mb-7",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-1 mb-3"},[_vm._v(_vm._s(_vm.$t('policyAccess.partnerDetails.buildingName')))]),_c('ValidationObserver',{ref:"observer"},[_c('ValidationProvider',{staticClass:"addresses",attrs:{"rules":"required","vid":"address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SelectAddress',{attrs:{"search-input":_vm.buildingSearch,"items":_vm.buildingSuggestionList,"no-filter":true,"placeholder":_vm.$t('policyAccess.partnerDetails.buildingPlaceholder'),"error-messages":errors[0],"clearable":true,"hide-no-data":true,"loading":_vm.buildingsLoading,"item-text":"title","item-value":"placeId","background-color":"white","data-test-id":"tenant-address-input"},on:{"update:searchInput":function($event){_vm.buildingSearch=$event},"update:search-input":function($event){_vm.buildingSearch=$event},"search":_vm.onBuildingSearch,"input":_vm.inputBuildingAddress,"change":_vm.onChangeBuildingAddress,"click:clear":_vm.resetBuildingInputs},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.highlightedTitle || item.title)}})]}}],null,true),model:{value:(_vm.policyAccessInfo.address),callback:function ($$v) {_vm.$set(_vm.policyAccessInfo, "address", $$v)},expression:"policyAccessInfo.address"}})]}}])}),(_vm.policyAccessInfo.address.type === _vm.PropertiesTypeEnum.MULTIFAMILY)?_c('div',{staticClass:"text-subtitle-1 mb-3 mt-10"},[_vm._v(_vm._s(_vm.$t('policyAccess.partnerDetails.unitName')))]):_vm._e(),_c('ValidationProvider',{staticClass:"addresses",attrs:{"rules":"required","vid":"unitName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.policyAccessInfo.address.type === _vm.PropertiesTypeEnum.MULTIFAMILY)?_c('SelectAddress',{attrs:{"search-input":_vm.unitSearch,"items":_vm.unitSuggestionList,"placeholder":_vm.$t('policyAccess.partnerDetails.unitPlaceholder'),"error-messages":errors[0],"item-text":"title","item-value":"placeId","background-color":"white","data-test-id":"current-address-input"},on:{"update:searchInput":function($event){_vm.unitSearch=$event},"update:search-input":function($event){_vm.unitSearch=$event}},scopedSlots:_vm._u([{key:"noData",fn:function(){return [_c('span',{staticClass:"nodata"},[_vm._v(_vm._s(_vm.$t('validation.no_data')))])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.highlightedTitle || item.title)}})]}}],null,true),model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}):_vm._e()]}}])})],1)],1)],1),_c('PartnerAccept',{attrs:{"isDisabled":_vm.isDisabled,"unit-id":_vm.address.placeId,"building-id":_vm.policyAccessInfo.address.placeId}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }