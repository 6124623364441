
















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PolicyDepositAccessAdditionInfoSection extends Vue {
  private get content() {
    return ([
      {
        title: this.$tc('policyAccess.additionalInfo.content.0.title'),
        text: this.$tc('policyAccess.additionalInfo.content.0.text'),
        class: 'first-demand'
      },
      {
        title: this.$tc('policyAccess.additionalInfo.content.1.title'),
        text: this.$tc('policyAccess.additionalInfo.content.1.text'),
        class: 'cost-saving'
      },
      {
        title: this.$tc('policyAccess.additionalInfo.content.2.title'),
        text: this.$tc('policyAccess.additionalInfo.content.2.text'),
        class: 'social-impact'
      }           
    ]);
  }
}
