

















import {Component, Vue} from 'vue-property-decorator';
import {EnvProvider} from '@/utilities';
import { getSiteUrl } from '@/utilities/getUrl';

@Component({
  components: {}
})
export default class SuccessHelpSection extends Vue {
  private get getContactUsLink(): string {
    return getSiteUrl(EnvProvider('URL_WEBSITE_CONTACT_US'));
  }

  private get getFaqLink(): string {
    return getSiteUrl(EnvProvider('URL_WEBSITE_FAQ'));
  }
}
