


















import {Component, Vue} from 'vue-property-decorator';
import {IDescription} from '@/interfaces';
import sirius from '@/assets/images/PolicyDepositeAccess/sirius-sm.svg';
import greenLight from '@/assets/images/PolicyDepositeAccess/greenlightre-sm.svg';
import hannover from '@/assets/images/PolicyDepositeAccess/hannover-re-sm.svg';

@Component
export default class PolicyDepositAccessPartnersSection extends Vue {
  public get getItems(): IDescription[] {
    return [
      {
        title: this.$tc('policyAccess.partners.items.3.title'),
        component: hannover,
        class: 'greenLight'
      },
      {
        title: this.$tc('policyAccess.partners.items.1.title'),
        component: sirius,
        class: 'sirius'
      },
      {
        title: this.$tc('policyAccess.partners.items.2.title'),
        component: greenLight,
        class: 'greenLight'
      },
    ];
  }
}
