




































import {Component, Vue} from 'vue-property-decorator';
import headImage from '@/assets/icons/policyAccess/tenant-invites-landlord-illustration.svg';
import {IPolicyAccess} from '@/services/api/user.service';
import Quote from '@/components/Quote.vue';

@Component({
  components: {
    headImage, 
    Quote
  }
})
export default class PolicyDepositAccessHeadSection extends Vue {
  private get getPolicyInfo(): IPolicyAccess {
    return this.$store.getters.getAcceptPolicy;
  }
}
