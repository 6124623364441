











import {Component, Vue} from 'vue-property-decorator';
import PolicyDepositAccessSummaryInfo from '@/pages/PolicyDepositAccess/PolicyDepositAccess.summary.info.vue';
import ExistingLandlordForm from '@/pages/PolicyDepositAccess/ExistingLandlord/Form/PolicyDepositAccess.form.vue';

@Component({
  components: {PolicyDepositAccessSummaryInfo, ExistingLandlordForm}
})
export default class PolicyDepositAccessExistingLandlordSection extends Vue {

}
