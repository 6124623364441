































import {Component, Vue} from 'vue-property-decorator';
import {ValidationProvider} from 'vee-validate';
import {IPolicyLandlordDetails} from '@/services/api/user.service';
import {createHelpers} from 'vuex-map-fields';
import Autocomplete from '@/components/Ui/Autocomplete.vue';

const { mapFields } = createHelpers({
  getterType: 'getUserFields',
  mutationType: 'updateUserField',
});

@Component({
  components: { ValidationProvider, Autocomplete},
  computed: { ...mapFields(['policyLandlordDetails']) }
})

export default class PolicyAccessSelectBeneficiary extends Vue {

  private policyLandlordDetails!: IPolicyLandlordDetails;

  public mounted() {
    this.$store.dispatch('getBeneficiaries',
      {
        token: this.$route.query.token,
        params: {
          page: this.$store.getters.getBeneficiariesPage,
          pageSize: 10,
        }
      });
  }

  private get getBeneficiaries() {
    return this.$store.getters.getBeneficiaries;
  }

  private dispatchBeneficiaries(_entries, _observer, isIntersecting) {
    if (isIntersecting && this.getBeneficiaries.length < this.$store.getters.getBeneficiariesCount) {
      this.$store.dispatch('getBeneficiaries',
        {
          token: this.$route.query.token,
          params: {
            page: this.$store.getters.getBeneficiariesPage + 1,
            pageSize: 10,
          }
        });
    }
  }

  private setExistingBeneficiaryManual() {
    this.$emit('setExistingBeneficiaryManual', false);
  }
}
