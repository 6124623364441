












import { Component, Prop, Vue } from 'vue-property-decorator';
import { scrollTop } from '@/utilities';

@Component
export default class PartnerAccept extends Vue {
  private loading: boolean = false;

  @Prop({type: Boolean, required: true, default: false})
  private isDisabled!: boolean;

  @Prop({type: String, default: null})
  private unitId!: string | null;

  @Prop({type: String, default: null})
  private buildingId!: string | null;

  private onSubmit() {
    this.loading = true;
    this.$store.dispatch('acceptPartner', {
      token: this.$route.query.token,
      buildingId: this.buildingId,
      unitId: this.unitId,
    })
    .then(() => {
      scrollTop();
      this.$root.$emit(
        'acceptSucceeded',
        () => true
      );
    })
      .finally(() => this.loading = false);
  }
}
